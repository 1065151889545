.achievements{
    padding: 2% 4%;
    background: #EFEFFF;
}

.achievements .welcome-message{
    margin-bottom: 2rem;
}

.achievements .welcome-message p{
    color: #555555;
}

.created-data{
    display: flex;
}

.created-data img{
    margin-right: 0.5rem;
}