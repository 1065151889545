.class-info {
  margin-top: 2rem;
}

.class-info-headings {
  display: flex;
  list-style-type: none;
}

.class-info-heading {
  font-family: "DM Sans", sans-serif;
  margin-right: 4rem;
  cursor: pointer;
  color: #8a8a8a;
  font-size: 1.125rem;
}

.active {
  font-weight: 700;
  color: black;
}

.active::after {
  content: "";
  display: block;
  border: 2px solid #7777f9;
  border-radius: 10px;
  background-color: #7777f9;
}

.class-info-contents {
  margin-top: 2rem;
}

@media screen and (max-width: 450px) {
  .class-info-contents {
    width: 100%;
    margin-top: 2rem;
  }

  .class-info-heading {
    margin-right: 15px;
    font-size: 1rem;
  }

  .active::after {
    width: 100%;
    margin-left: 0;
  }
}
