@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Inter:wght@300;400;600;800;900&family=Lato:wght@400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

a{
  text-decoration: none;
}

.dashboard {
  display: flex;
  position: relative;
  background: #efefff;
}

.sidebar {
  flex: 1;
  height: 100vh;
  background: #ffffff;
  padding: 2% 0;
  border-radius: 0px 30px 30px 0px;
  position: sticky;
  top: 0;
  bottom: 0;
}

.content {
  flex: 9;
  top: 0;
}

.mobile-view {
  display: none;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  width: 100%;
  height: 100%;
}

/* Responsive styling for the dashboard */
@media screen and (max-width: 450px) {
  .sidebar {
    display: none;
    position: absolute;
    left: -100vw;
    top: 0;
    z-index: 5;
    width: 70%;
    border: none;
    border-top-left-radius: 0;
  }

  .sidebar-mobile {
    display: block;
    position: absolute;
    left: -100vw;
    top: 0;
    background: #fff;
    z-index: 5;
    height: 150vh;
    padding-top: 2rem;
    width: 70%;
    border: none;
    border-top-left-radius: 0;
  }

  .mobile-view {
    display: block;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    width: 100%;
    height: 3rem;
    padding: 0 4%;
  }

  .mobile-view img {
    width: 40%;
    object-fit: contain;
  }

  .mobile-view .hamburger {
    font-size: 2.5rem;
    color: #516beb;
    z-index: 4;
  }

  .open {
    position: fixed;
    top: 0;
    left: 0;
    transition: all ease-in-out 3s;
  }
}