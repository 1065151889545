
.progressbar{
    display: flex;
    align-items: center;
}

.containerStyles{
    height: 10px;
    width: 15rem;
    background: #F9F5FF;
    border-radius: 4px;
    margin-right: 0.5rem;
  }

  .fillerStyles{
    height: 100%;
    background-color: #7777F9;
    border-radius: inherit;
    text-align: right;
  }

  .labelStyles{
    color: 'white';
    font-weight: 'bold'
  }

  .main-table-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .main-table-header button{
    height: 2rem;
    width: 6rem;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    background: #F9FAFB;
    font-family: 'DM Sans', sans-serif;
    font-size: 0.8rem;
  }

  .main-table-header button:nth-child(1){
    border-radius: 8px 0px 0px 8px;
  }

  .main-table-header button:nth-child(2){
      border-radius: 0px;
  }

  .main-table-header button:nth-child(3){
    border-radius: 0px 8px 8px 0px;
  }

  .main-table-header input{
    width: 25rem;
    height: 3rem;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
    padding: 12px 16px 12px 45px;
    background-image: url('../../../../assets/images/search.png');
    background-repeat: no-repeat;
    background-position-x: 5%;
    background-position-y: 50%;
}

.main-table-header input::placeholder{
    font-size: 0.875rem;
}

.main-table-header input:focus{
    outline: none;
    border: 1px solid #6363C7;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1);
}

/* range range-bar range-bar-fill */