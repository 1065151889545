.password-info-heading{
    display: flex;
    flex-direction: column;
}

.password-info-heading h5{
    font-size: 1rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}

.password-info-heading p{
    font-size: 0.8rem;
}

.created-password-data{
    display: flex;
}

.created-password-data>div:first-child{
    flex: 1;
}

.created-password-data>div:nth-child(2){
    flex: 2;
}

.created-password-data p{
    font-family: 'DM Sans', sans-serif;
    font-weight: 600;
}

.created-password-data input{
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 80%;
    height: 2.5rem;
    padding: 20px;
}

.created-password-data input:focus{
    border: 1px solid #7F56D9;
    outline: none;
}

.created-password-data .new-password-inputs p{
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.created-password-data-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.created-password-data-buttons .update-password{
    display: flex;
    align-items: center;
}

.created-password-data-buttons .update-password .update-password-btn,
.created-password-data-buttons .update-password .cancel-btn{
    font-size: 0.8rem;
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
}

.created-password-data-buttons .update-password .cancel-btn{
    margin-right: 1rem;
    width: 6rem;
    height: 2.5rem;
    border: 1px solid #D0D5DD;
    background: #fff;
}

.created-password-data-buttons .update-password .update-password-btn{
    width: 12rem;
    height: 2.5rem;
    background: #7F56D9;
    border: 1px solid #7F56D9;
    color: #fff;
}