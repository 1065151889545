.recent-classes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent-class {
  cursor: pointer;
}

.recent-class .class-image {
  width: 16rem;
  height: 10rem;
}

.recent-class .class-image img {
  /* width: 100%; */
  height: 100%;
}

.recent-class .class-title {
  color: #121212;
  font-size: 1rem;
  font-weight: 700;
  margin: 10px 0;
  font-family: "DM Sans", sans-serif;
}

.recent-class .class-tutor {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent-class .class-tutor > div:first-child {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.recent-class .class-tutor .class-tutor-name {
  color: #555555;
  font-weight: 400;
  margin-left: 5px;
  font-family: "Lato", sans-serif;
}

.recent-class .class-tutor .person-icon {
  color: #b8b8d2;
}

.recent-class .class-tutor .class-length {
  width: 3rem;
  height: 1rem;
  background: rgba(147, 167, 246, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recent-class .class-tutor .class-length p {
  font-size: 0.573rem;
  color: #274fed;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}

/* Responsive styling for the student recent classes */
@media screen and (max-width: 450px) {
  .recent-classes {
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: scroll;
    height: 30rem;
  }

  .recent-classes::-webkit-scrollbar {
    width: 2px;
  }

  .recent-classes::-webkit-scrollbar-track {
    background: #516beb;
    width: 3px;
  }

  .recent-classes::-webkit-scrollbar-thumb {
    outline: 5px solid #516beb;
    border-radius: 13px;
    width: 1rem;
    height: 1rem;
  }

  .recent-class {
    width: 80%;
    margin-bottom: 2rem;
  }

  .recent-class .class-image {
    width: 100%;
  }

  .recent-class .class-image img {
    object-fit: cover;
  }

  .recent-class .class-tutor {
    width: 100%;
  }
}
