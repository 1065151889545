.my-details{
    
}

.my-details .personal-info-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my-details .personal-info-heading>div:first-child{
    display: flex;
    flex-direction: column;
}

.my-details .personal-info-heading>div:first-child h5{
    font-family: 'DM Sans', sans-serif;
    font-weight: 700;
    color: #101828;
    font-size: 1rem;
}

.my-details .personal-info-heading>div:first-child p{
    color: #667085;
    font-size: 0.8rem;
}

.my-details .personal-info-heading>div:nth-child(2){
    display: flex;
    align-items: center;
}

.my-details .personal-info-heading>div:nth-child(2)>button{
    width: 4rem;
    height: 2rem;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-size: 0.8rem;
}

.my-details .personal-info-heading>div:nth-child(2)>button:first-child{
    background: #fff;
    border: 1px solid #D0D5DD;
    margin-right: 1rem;
    color: #344054;
}

.my-details .personal-info-heading>div:nth-child(2)>button:nth-child(2){
    background: #7777F9;
    border: 1px solid #7777F9;
    color: #fff;
}

.created-personal-data{
    display: flex;
    align-items: center;
}

.created-personal-data>div:first-child{
    flex: 1;
    font-family: 'DM Sans', sans-serif;
}

.created-personal-data>div:nth-child(2){
    flex: 2;
}

.created-personal-data .name p, .created-personal-data .email p, .created-personal-data .school p, .created-personal-data .class p{
    font-size: 0.9rem;
    font-weight: 600;
}

.created-personal-data .name-inputs input{
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 14rem;
    height: 2.5rem;
    background: #fff;
    margin-right: 1rem;
    padding: 20px;
}

.created-personal-data .email-inputs input, .created-personal-data .school-inputs input, .created-personal-data .class-inputs input {
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 80%;
    height: 2.5rem;
    background: #fff;
    margin-right: 1rem;
    padding: 20px;
}

.created-personal-data .your-photo h5{
    font-size: 0.9rem;
    font-weight: 600;
}

.created-personal-data .your-photo p{
    font-size: 0.7rem;
}

.created-personal-data .your-photo-inputs {
    display: flex;
    align-items: flex-start;
}

.created-personal-data .your-photo-inputs img{
    margin-right: 1rem;
}

.created-personal-data .your-photo-inputs input{
    width: 60%;
    height: 5rem;
    background: #FFFFFF;
    border: 1px dashed #E4E7EC;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 20px;
}

.created-personal-data .your-photo-inputs>input:focus{
    outline: none;
    border: 1px solid #7777F9;
}