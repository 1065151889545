.student-dashboard {
  padding: 2% 4%;
}

.student-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-dashboard-header input {
  width: 25rem;
  height: 3.125rem;
  border: 1px solid #e1e3e6;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  padding: 12px 16px 12px 45px;
  background-image: url("../../assets/images/search.png");
  background-repeat: no-repeat;
  background-position-x: 5%;
  background-position-y: 50%;
}

.student-dashboard-header input::placeholder {
  font-size: 0.875rem;
}

.student-dashboard-header input:focus {
  outline: none;
  border: 1px solid #6363c7;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1);
}

.student-user-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 50%;
}

.student-user-icon img {
  width: 100%;
}

.student-dashboard-header-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-dashboard-header-details .notification-icon {
  margin: 0 20px;
  cursor: pointer;
  color: #4e4b66;
}

.student-dashboard-header-details a {
  text-decoration: none;
}

.student-dashboard-header-details a div {
  display: flex;
  align-items: center;
}

.student-dashboard-header-details a div span {
  margin-left: 10px;
  font-family: "Lato", sans-serif;
  color: #6e7191;
  font-size: 1rem;
}

.student-detail {
  display: flex;
  align-items: center;
}

.student-detail div {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  font-size: 0.875rem;
  font-family: "Lato", sans-serif;
  color: #4e4b66;
}

/* Responsive styling for the student dashboard header */
@media screen and (max-width: 450px) {
  .student-dashboard-header {
    margin-top: 4rem;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .student-dashboard-header input {
    width: 100%;
    margin-top: 2rem;
  }

  .student-dashboard-header-details {
    width: 100%;
    justify-content: space-around;
  }
}

@media screen and (min-width: 451px) and (max-width: 767px) {
  .student-dashboard-header {
    margin-top: 4rem;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .student-dashboard-header input {
    width: 100%;
    margin-top: 1rem;
    background-position-x: 1%;
  }
}