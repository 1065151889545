.signup-pagetwo {
  padding: 1% 7%;
}

.signup-pagetwo .signupcontents {
  display: flex;
  margin-top: 3rem;
  align-items: flex-start;
  justify-content: space-evenly;
}

.signup-pagetwo .signupcontents .contentOne {
  width: 60%;
  padding-left: 10%;
}

.signup-pagetwo .signupcontents .contentTwo {
  width: 40%;
}

.signup-pagetwo .signupcontents .contentOne img {
  width: 50%;
  margin-top: 5rem;
}

.contentTwo h4 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.contentTwo .form-two div {
  width: 70%;
}

.contentTwo .form-two div input {
  width: 100%;
  height: 48px;
  margin-bottom: 1rem;
  padding-left: 20px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}

.contentTwo .form-two div input:focus {
  outline: none;
  border: 1px solid #6363c7;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1);
}

.contentTwo .form-two div input::placeholder {
  font-size: 0.813rem;
}

.contentTwo .form-two div button {
  width: 100%;
  height: 48px;
  margin-top: 2rem;
  background: #6363c7;
  color: #fff;
  font-size: 0.813rem;
  border: none;
  border-radius: 4px;
  background-image: url("../../../assets/images/arrowicon.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .signup-pagetwo {
    padding: 5% 4%;
    width: 100%;
  }

  .signup-pagetwo .header img {
    width: 40%;
  }

  .signup-pagetwo .signupcontents {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .signup-pagetwo .signupcontents .contentOne {
    width: 100%;
    display: none;
  }

  .signup-pagetwo .signupcontents .contentTwo {
    width: 100%;
    text-align: center;
  }

  .form-two {
    padding: 0 7%;
  }

  .contentTwo .form-two div {
    width: 100%;
  }
}

@media screen and (min-width: 451px) and (max-width: 767px) {
  .signup-pagetwo {
    padding: 5% 4%;
    width: 100%;
  }

  .signup-pagetwo .header img {
    width: 40%;
  }

  .signup-pagetwo .signupcontents {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .signup-pagetwo .signupcontents .contentOne {
    width: 100%;
    display: none;
  }

  .signup-pagetwo .signupcontents .contentTwo {
    width: 100%;
    text-align: center;
  }

  .form-two {
    padding: 0 7%;
  }

  .contentTwo .form-two div {
    width: 100%;
  }
}
