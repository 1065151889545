.plan-heading h5{
    font-family: 'DM Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
}

.plan-heading p{
    font-size: 0.8rem;
    color: #667085;
    margin-top: 0.5rem;
}

.plan-content{
    margin-top: 2rem;
    width: 90%;
    height: 9rem;
    background: #FFFFFF;
    border: 1px solid #E4E7EC;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}

.plan-content>div:first-child{
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    border: 1px solid #E4E7EC;
    padding: 0.5rem 1rem;
}

.plan-content>div:first-child div{
    margin-right: 1rem;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #F4EBFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan-content>div:first-child h5{
    color: #344054;
    font-size: 1rem;
    font-weight: 600;
}

.plan-content>div:nth-child(2){
    height: 60%;
    width: 100%;
    padding: 0.5rem 1rem;
}

.plan-content>div:nth-child(2) p>span{
    color: #344054;
    font-size: 2rem;
    font-weight: 700;
}

.plan-content>div:nth-child(2) p{
    color: #667085;
    font-size: 0.9rem;
}