.sign-in-page {
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-page div {
  width: 100%;
}

.sign-in-page div h5 {
  margin-top: 1rem;
  font-size: 2rem;
  padding: 0 1rem;
}

.sign-in-page div .info {
  margin-top: 1rem;
  color: #667085;
}

.signin-form {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin-form-div {
  width: 70%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signin-form-div input:first-child,
.signin-form-div input:nth-child(2) {
  width: 25rem;
  height: 2.5rem;
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}

.signin-form-div input:first-child:focus,
.signin-form-div input:nth-child(2):focus {
  outline: none;
  border: 1px solid #6363c7;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1);
}

.signin-form-div label,
.signin-form-div label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.signin-form-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.signin-form-checkbox div {
  display: flex;
  align-items: center;
}

.signin-form-checkbox div label {
  color: #344054;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.signin-form-checkbox a {
  color: #7777f9;
  text-decoration: none;
  font-size: 0.8rem;
  width: 50%;
}

.signin-form-btn {
  margin-top: 2rem;
}

.signin-form-btn button {
  width: 100%;
  height: 2.5rem;
  background: #7777f9;
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.no-account {
  margin-top: 2rem;
}

.no-account span .signup {
  color: #7777f9;
  text-decoration: none;
  cursor: pointer;
}

/* Responsive styling */
@media screen and (max-width: 450px) {
  .sign-in-page {
    padding: 5% 4%;
  }

  .sign-in-page div h5 {
    font-size: 1.5rem;
  }

  .sign-in-page div .info {
    font-size: 0.8rem;
  }

  .signin-form-div input:first-child,
  .signin-form-div input:nth-child(2) {
    width: 100%;
  }
}
