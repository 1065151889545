.documents-sect{
    padding: 2% 4%;
    background: #EFEFFF;
}

.documents-section-heading{
    margin: 2rem 0 1rem;
    font-weight: 400;
    font-size: 1rem;
}

.documents{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.document{
    width: 17rem;
    height: 10rem;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 3rem;
    margin-bottom: 3rem;
}

.document:last-child{
    margin-right: 0;
}

.document-img{
    height: 60%;
    background: rgba(115, 103, 240, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.document-details{
    height: 40%;
    background: #fff;
    padding: 10px;
}

.document-details>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.document-details>div:first-child>p:first-child{
    font-size: 0.875rem;
    font-weight: bold;
    color: #5E5873;
    font-family: 'DM Sans', sans-serif;
}

.document-details>div:first-child>p:nth-child(2){
    font-size: 0.75rem;
    font-weight: 400;
    color: #B9B9C3;
    font-family: 'DM Sans', sans-serif;
}

.document-details>div:nth-child(2){
    font-size: 0.75rem;
}

.document-details>div:nth-child(2)>p{
    color: #B9B9C3;
}

.empty_doc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .documents-sect{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .documents{
        width: 100%;
    }

    .document{
        width: 100%;
        margin-right: 0;
    }

    .document-img{
        height: 70%;
    }
}