.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  width: 100vw;
  height: 100vh;
}

.modal-container {
  width: 30rem;
  background: #f9fbff;
  border-radius: 12px;
  padding: 1rem;
}

.modal-content .close-modal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-content .modal-content-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content .modal-content-heading h4 {
  font-weight: 500;
  color: #374151;
  font-size: 1rem;
  margin-top: 1rem;
}

.modal-content-details {
  width: 100%;
  height: 4rem;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
}

.modal-content-details div {
  width: 25%;
}

.modal-content-details div p {
  color: #374151;
  font-size: 0.8rem;
}

.modal-content-details > div:last-child {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  width: 6rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-content-details > div:nth-child(2) > p span {
  color: #374151;
  font-weight: 600;
}

.modal-content-details > div:nth-child(3) > p span {
  color: #7777f9;
}

.modal-content .more-information {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8rem;
}

.modal-content .more-information p {
  font-size: 0.8rem;
  color: #9ca3af;
}
