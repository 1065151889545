.asignment-item-list {
    /* display: flex;
    flex-wrap: wrap;
    align-items: center; */

    display: grid;
    flex-wrap: wrap;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
}

.assignment-item {
    padding: 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 8.95px 13.425px -4.475px rgba(184, 184, 210, 0.2);
    border-radius: 13.425px;
    /* width: 18.5rem; */
    /* margin-right: 1rem; */
    /* margin-bottom: 1rem; */
}

.assignment-item:last-child {
    margin-right: 0;
}

.asignment-item-top {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
}

.asignment-item-top svg {
    margin-right: 1rem;
}

.assignment-subject-tutor h5 {
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
}

.assignment-subject-tutor p {
    font-weight: 300;
    font-size: 0.8rem;
    color: #9E9E9E;
}

.assingment-item-body {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assingment-dates p {
    font-weight: 400;
    font-size: 0.75rem;
    color: #C1C1CE;
    margin-bottom: 0.4rem;
}

.assingment-dates h6 {
    font-weight: 600;
    font-size: 10px;
}

.assignment-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.assigment-status {
    width: 50%;
    border-radius: 6.5px;
    padding: 0.5rem;
    text-align: center;
}

.submitted {
    background: #F3E4FF;
}

.submitted p {
    font-weight: 600;
    font-size: 0.8rem;
    color: #8F00FF;
}

.unattended {
    background: #E8EFF6;
}

.unattended p {
    font-weight: 600;
    font-size: 0.8rem;
    color: #121212;
}

.upload_assignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    background: #7777F9;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 0.8rem;
    cursor: pointer;
}

.upload_assignment_modal h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #14142B;
    margin-bottom: 10px;
}

.upload_assignment_modal p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #6E7191;
}

.upload_assignment_form {
    margin-top: 2rem;
}

.upload_assignment_form_item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 5px;
}

.upload_assignment_form_item label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #98A2B3;
}

.upload_assignment_form_item input {
    border: 1px solid #EFF0F7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #14142B;
    outline: none;
}

.upload_assignment_form_item textarea {
    border: 1px solid #EFF0F7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #14142B;
    outline: none;
}

.upload_assignment_form_item select {
    border: 1px solid #EFF0F7;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #14142B;
    outline: none;
}

/* focus */
.upload_assignment_form_item input:focus {
    border: 1px solid #7777F9;
}

.upload_assignment_form_item select:focus {
    border: 1px solid #7777F9;
}

.upload_assignment_form_item textarea:focus {
    border: 1px solid #7777F9;
}

.upload_label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: auto;
    position: relative;
    cursor: pointer;
    color: #7777F9 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    margin-top: 1rem;
    border: 1px solid #EFF0F7;
    border-radius: 4px;
    padding: 10px;

}

.upload_label p {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #4E4B66 !important;
}

.upload_assignment_form_item input[type="file"] {
    display: none;
}

.upload_assignment_form_item_btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 1rem;
}

.cancel_ass {
    background: #FFFFFF;
    border: 1px solid #EFF0F7;
    border-radius: 4px;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #7777F9;
    cursor: pointer;
}

.submit_ass {
    background: #7777F9;
    border-radius: 4px;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
}


.upload_web{
    display: flex;
}

.upload_mobile{
    display: none;
}


@media screen and (max-width: 450px) {
    .asignment-item-list {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .upload_web{
        display: none;
    }

    .upload_mobile{
        display: flex;
    }
  }