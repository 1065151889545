.topClassesAndPerformers {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 1115px) {
  .topClassesAndPerformers {
    flex-direction: column;
  }

}

.top-classes {
  flex: 2.8;
}

.top-performers {
  flex: 1.2;
}

.top-performers-header {
  margin-bottom: 1.5rem;
}

.top-performers-body {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 0.8rem;
}

.top-performers-body-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.performers-divider {
  border: 1px solid #282C38;
  margin-bottom: 2rem;
}

.language-div {
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.language-icon {
  margin-right: 0.5rem;
}

.language-div p {
  font-weight: 400;
  font-size: 13px;
  color: #121212;
}

.language-div svg {
  cursor: pointer;
  margin-left: 5px;
}

.show-more-btn button {
  background: #5D5FEF;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFFFFF;
  outline: none;
  border: none;
  height: 35px;
  padding: 0 0.5rem;
  cursor: pointer;
}

.top-classes-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  margin-right: 1.3rem;
}

.top-classes-content {
  width: 33%;
  /* margin-bottom: 1.7rem; */
  /* margin-right: 1.7rem; */
}



.top-classes-content-img {
  width: 100%;
  height: 10rem;
  border-radius: 8px;
}

/* .top-classes-content-img img {
  width: 100%;
  height: 100%;
} */

.top-classes-content-details {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 2rem;
  /* margin-top: 0.5rem; */
}

.top-classes-content-details div {
  display: flex;
  align-items: center;
}

.top-classes-content-details div p {
  font-weight: 700;
  font-size: 0.65rem;
  color: #555555;
}

.top-classes-content-details>div .personIcon {
  color: #b8b8d2;
  font-size: 0.8rem;
  margin-right: 0.5rem;
}

.top-classes-content-details .no-of-chapters {
  font-size: 0.75rem;
  color: #555555;
}

.performing-student-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  justify-content: space-around;
}

.student-image {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
}

.student-image img {
  /* width: 100%; */
  height: 100%;
}

.performing-student-item p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  margin-right: 1.2rem;
}

.perfomance-range {
  display: flex;
  align-items: center;
}

.perfomance-range svg {
  margin-right: 1rem;
}

.perfomance-range .green {
  color: #30E0A1;
}

.perfomance-range .red {
  color: #FA2256;
}

.perfomance-range .orange {
  color: #FFB545;
}

.perfomance-range .blue {
  color: #246CF9;
}


.top_class_video {
  position: relative;
}

.video_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
}

.video_text h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #FFFFFF;
}

.video_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}

.video_text span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}

.video_text_bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.play_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}



/* Responsive styling for the student top classes and performers */
@media screen and (max-width: 450px) {
  .topClassesAndPerformers {
    flex-direction: column;
    width: 100%;
  }

  .top-classes-div {
    grid-template-columns: repeat(1, 1fr);

    /* flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%; */
  }

  .top-classes {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    height: 30rem;
  }

  .top-performers {
    flex: 1;
  }

  .top-classes-content {
    width: 80%;
    margin-right: 0;
  }

  .top-classes-content-img {
    width: 80%;
  }

  .top-classes-content-img img {
    object-fit: contain;
  }

  .top-classes-content-details {
    width: 80%;
  }
}