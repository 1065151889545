.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 14, 41, 0.7);
    backdrop-filter: blur(9px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    padding: 0 10px;
}

.modal_content {
    width: 500px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: all 0.3s ease-in-out;
}

.modal_header{
    position: relative;
}

.modal_header button{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 20px;
    color: #6D7A8F;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
}


@media screen and (max-width: 620px) {
    .modal .modal_content {
        width: 100%;
        padding: 15px;
        gap: 20px;
    }
    .modal_header button{
        bottom: -21px;
    }
}