.classes {
  padding: 2% 4%;
  background: #efefff;
}

.class-section-heading {
  margin: 2rem 0 1rem;
  font-weight: 400;
}

.single-classes {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.single-class {
  /* width: 17rem; */
  cursor: pointer;
  margin-right: 1rem;
  margin-bottom: 2rem;
}

.single-class:last-child{
  margin-right: 0;
}

.single-class .class-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-class .class-image img {
  width: 100%;
  height: 100%;
}

.single-class .class-title {
  color: #121212;
  font-size: 1rem;
  font-weight: 700;
  margin: 10px 0;
  font-family: "DM Sans", sans-serif;
}

.single-class .class-tutor {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-class .class-tutor > div:first-child {
  font-size: 0.75rem;
  display: flex;
  align-items: center;
}

.single-class .class-tutor .class-tutor-name {
  color: #555555;
  font-weight: 400;
  margin-left: 5px;
  font-family: "Lato", sans-serif;
}

.single-class .class-tutor .person-icon {
  color: #b8b8d2;
}

.single-class .class-tutor .class-length {
  background: rgba(147, 167, 246, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
}

.single-class .class-tutor .class-length p {
  font-size: 0.573rem;
  color: #274fed;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}

.video_grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

@media screen and (max-width: 450px) {
  .single-classes {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .single-class {
    width: 100%;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .single-class .class-image {
    width: 100%;
  }

  .single-class .class-tutor {
    width: 100%;
  }
}
