.student-dashboard {
  background: #efefff;
}

.welcome-message {
  margin-top: 2rem;
}

.welcome-message h2 {
  font-size: 2.18rem;
  font-family: "DM Sans", sans-serif;
}

.welcome-message p {
  font-size: 0.75rem;
  color: #8a8a8a;
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
  .welcome-message h2 {
    font-size: 16px;
  }
}
