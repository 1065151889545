.signup-pageone {
  padding: 1% 7%;
}

.signup-pageone .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup-pageone .header p {
  font-weight: 400;
  color: #666666;
  font-size: 0.875rem;
}

.signup-pageone .header p > span a {
  font-weight: 700;
  color: #111111;
  text-decoration: none;
}

.signup-pageone .signupcontents {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;
}

.signup-pageone .signupcontents .contentone {
  width: 60%;
}

.signup-pageone .signupcontents .contenttwo {
  width: 40%;
}

.get-your-profile-ready h2 {
  font-size: 4rem;
  font-weight: 700;
}

.content-one > div:nth-child(2) {
  margin-top: 9rem;
}

.content-one p {
  font-size: 0.7rem;
}

.content-one p span a {
  font-weight: 700;
  color: #111111;
  margin: 0 0.5rem;
}

.signupformone h4 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.signupformone p {
  font-size: 0.875rem;
  color: #000000;
  margin-bottom: 3rem;
}

.form-one .form-one-div {
  width: 70%;
  margin-bottom: 1rem;
}

.form-one div input,
.form-one div select {
  width: 100%;
  height: 48px;
  padding-left: 20px;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}

.form-one div select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../../../assets/images/dropdown.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}

.form-one div input:focus,
.form-one div select:focus {
  outline: none;
  border: 1px solid #6363c7;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px rgba(39, 79, 237, 0.1);
}

.form-one div input::placeholder,
.form-one div select option {
  font-size: 0.813rem;
}

.form-one div:first-child {
  display: flex;
  justify-content: space-between;
}

.form-one div:first-child div {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.form-one div:last-child button {
  width: 100%;
  height: 48px;
  margin-top: 2rem;
  background: #6363c7;
  color: #fff;
  font-size: 0.813rem;
  border: none;
  border-radius: 4px;
  background-image: url("../../../assets/images/arrowicon.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  cursor: pointer;
}

/* ////////////////////////////////////////// */
/* // responsive styling for sign up page one. */
@media screen and (max-width: 450px) {
  .signup-pageone {
    padding: 5% 4%;
    width: 100%;
  }

  .signup-pageone .header img {
    width: 70%;
  }

  /* //this is the mobile styling for signupcontents */
  .signup-pageone .signupcontents {
    /* removed display: flex from here cos repetition; */
    /* the problem was calling flex-direction before display */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .signup-pageone .signupcontents .contentone {
    width: 100%;
    display: none;
  }

  .signup-pageone .signupcontents .contenttwo {
    width: 100%;
  }

  .content-one > div:nth-child(2) {
    margin-top: 3rem;
  }

  .signupformone {
    text-align: center;
  }

  .form-one {
    padding: 0 7%;
  }

  .form-one .form-one-div {
    width: 100%;
  }

  .form-one div:first-child {
    flex-direction: column;
  }

  .form-one div:first-child div {
    width: 100%;
  }

  .form-one div:first-child div:first-child {
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 451px) and (max-width: 767px) {
  .signup-pageone {
    padding: 2% 4%;
    width: 100%;
  }

  .signup-pageone .header img {
    width: 70%;
  }

  /* //this is the mobile styling for signupcontents */
  .signup-pageone .signupcontents {
    /* removed display: flex from here cos repetition; */
    /* the problem was calling flex-direction before display */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .signup-pageone .signupcontents .contentone {
    width: 100%;
    display: none;
  }

  .signup-pageone .signupcontents .contenttwo {
    width: 100%;
  }

  .content-one > div:nth-child(2) {
    margin-top: 3rem;
  }

  .signupformone {
    text-align: center;
  }

  .form-one {
    padding: 0 7%;
  }

  .form-one .form-one-div {
    width: 100%;
  }

  .form-one div:first-child {
    flex-direction: column;
  }

  .form-one div:first-child div {
    width: 100%;
  }

  .form-one div:first-child div:first-child {
    margin-bottom: 1rem;
  }
}
